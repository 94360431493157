import axios from '@/common/axios'
import qs from 'qs'

export function updateScoreRule (data) {
  return axios({
    method: 'put',
    url: '/score/updateRule',
    data: qs.stringify(data)
  })
}

export function selectScoreRuleList () {
  return axios({
    method: 'get',
    url: '/score/ruleList'
  })
}
