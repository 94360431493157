<template>
  <div id="scoreRule">
    <el-dialog
      title="修改积分规则"
      width="360px"
      :visible.sync="scoreRuleDialogVisible"
      :close-on-click-modal="false"
    >
      <el-form
        ref="scoreRuleFormRef"
        :model="scoreRuleForm"
        :rules="scoreRuleFormRules"
        label-position="right"
        label-width="100px"
      >
        <el-row>
          <el-col :span="24">
            <el-form-item label="规则名称">
              <el-input v-model="name" readonly />
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="积分" prop="score">
              <el-input v-model="scoreRuleForm.score" placeholder="请输入积分" clearable />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer">
        <el-button @click="scoreRuleDialogVisible = false">
          取 消
        </el-button>
        <el-button type="primary" @click="scoreRuleFormSubmit">
          确 定
        </el-button>
      </div>
    </el-dialog>
    <el-table
      :data="scoreRuleList"
      row-key="id"
      :cell-style="{padding: '6px'}"
      :header-cell-style="{background: '#f8f8f9'}"
      :height="getTableHeightNoPage()"
    >
      <el-table-column prop="name" label="规则名称" />
      <el-table-column prop="score" label="积分" />
      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <el-button
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="handleUpdate(scope.$index, scope.row)"
          >
            修改
          </el-button>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import { updateScoreRule, selectScoreRuleList } from '@/api/main/scoreRule'

export default {
  data () {
    return {
      scoreRuleDialogVisible: false,
      scoreRuleForm: {
        id: '',
        score: ''
      },
      scoreRuleFormRules: {
        score: [{ required: true, message: '积分不能为空', trigger: ['blur', 'change']}]
      },
      scoreRuleList: [],
      name: ''
    }
  },
  created () {
    selectScoreRuleList().then(res => {
      this.scoreRuleList = res.list
    })
  },
  methods: {
    scoreRuleFormSubmit () {
      this.$refs.scoreRuleFormRef.validate(async valid => {
        if (valid) {
          await updateScoreRule(this.scoreRuleForm)
          this.scoreRuleList = (await selectScoreRuleList()).list
          this.scoreRuleDialogVisible = false
        }
      })
    },
    handleUpdate (index, row) {
      this.scoreRuleDialogVisible = true
      this.scoreRuleForm.id = row.id
      this.name = row.name
      this.scoreRuleForm.score = row.score
    }
  }
}
</script>

<style>

</style>
